/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, StateCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Own voice processing hearing aids help wearers distinguish what they are saying instantly and independently from other sounds and voices around them. ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "Hear.com"), " has the ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " you need to improve your hearing while giving you back your voice."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-importance-of-hearing-your-own-voice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-importance-of-hearing-your-own-voice",
    "aria-label": "the importance of hearing your own voice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Importance of Hearing Your Own Voice"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ability to hear yourself speak is a valuable part of communication. If you are unable to hear your own voice when talking, or if your voice sounds different, it could cause problems when speaking to others."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unfortunately, many people choose to forego hearing aids if they cannot hear their own voice, which can be problematic. Talking to others and understanding what they say will be a significant issue. Those experiencing hearing loss can become socially isolated or depressed if they feel they cannot communicate clearly and effectively with others."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-own-voice-processing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-own-voice-processing",
    "aria-label": "what is own voice processing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Is Own Voice Processing?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s common for your voice to sound different while wearing a hearing aid. The sound the microphone receives will differ significantly from the sound the eardrum receives when a person talks."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Factors such as ear pinna effects, ear canal resonance, and head shadow and diffraction influence the sound that reaches the eardrum. Bone conduction also contributes to how a person perceives their voice."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with normal hearing depend on bone conduction for a natural-sounding voice. Anyone who has hearing loss also depends on bone conduction. However, hearing high-frequency components of their speech is no longer possible."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When someone wears hearing aids and speaks, they need to be able to process the sound of their voice correctly. Instead of hearing someone talk that they don't recognize, the hearing aids should process the wearer's voice separately from other sounds. The way to accomplish this is with OVP, meaning own voice processing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "OVP detects a person’s voice and processes it separately from external sounds, so the sound of their voice is more natural. The ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "technology"), " doesn’t affect other sounds. Instead, it identifies a signal while the wearer speaks and applies a dedicated setting, so they hear their voice as expected. A different setting only applies in the presence of external sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Silicon microphone networks detect various types of sound, including a person's voice traveling from their mouth to the hearing aid. Both hearing aids can communicate with each other and share data, so the wearer detects their own voice while speaking. The sound is completely separate from other people talking and ambient noises but still provides a natural hearing experience."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "efficacy-of-ovp-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#efficacy-of-ovp-technology",
    "aria-label": "efficacy of ovp technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Efficacy of OVP Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are multiple hearing aids for OVP on the market. The Audiology Clinic of the University of Northern Colorado studied the efficacy of own voice processing technology."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Twenty-one people with an average age of 67 participated in the study. All had never worn hearing aids before and suffered from bilateral symmetrical downward-sloping hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The researchers fitted each participant with Signia Pure 7Nx hearing aids. Participants also completed OVP training. The training included:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Counting from 21 and up"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reading nursery rhymes out loud"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Comparing four different OVP settings"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once the participants completed their OVP training, they selected their preferred setting. All chose either the moderate or strong setting."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The researchers performed various tests so the participants could hear their voices while wearing the hearing aids with OVP technology. Each participant's own voice satisfaction depended on different fitting algorithms, ear canal coupling, and additional factors the researchers used."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The participants of this study noted an improvement in their own voice satisfaction while wearing Signia OVP. Compared to competitive products using similar ear canal coupling and fitting methods, the Signia OVP led to higher satisfaction rates among the participants."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "an-ovp-solution-that-works",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#an-ovp-solution-that-works",
    "aria-label": "an ovp solution that works permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "An OVP Solution that Works"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear.com offers a variety of own voice-processing hearing aids. Each contains cutting-edge technology to help people with mild to severe hearing loss. We only partner with leading manufacturers worldwide, so our customers have access to medical-grade hearing aids that provide many benefits."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A few of our favorite hearing aids are below."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Horizon Go IX Hearing Aids")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The award-winning design of the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX hearing aids"), " by hear.com makes them a popular choice. Unlike other hearing aids on the market, it comes with features and industry-leading technologies, such as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Speech focus – You can use this function for extra speech clarity in challenging situations."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Bluetooth connectivity – ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Connect the hearing aids to Bluetooth"), " and stream TV shows, music, and phone calls."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Relax mode – Switch the settings to relax mode when you want to unwind and relax to the sounds of the ocean."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rechargeable – Recharge your hearing aids wirelessly for up to 9 hours of use."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "IP68-rated – This rating means the Horizon Go IX hearing aids are sweat, dirt, and moisture-resistant."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Signia Silk X")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia Silk X hearing aids are nearly invisible in the ears. A unique algorithm gives the wearer better than normal hearing. It also comes with convenient features, such as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tiny, barely noticeable design to fit inside the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Crystal-clear speech clarity"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Connect to and control the hearing aids using a smartphone"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Starkey Livio AI")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Starkey Livio AI hearing aids use artificial intelligence to provide various benefits to the wearer, including:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Translating different languages"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Recharging batteries that can last for more than 20 hours"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Connecting to a smartphone using Bluetooth"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tracking activity and monitoring health, including detecting a fall"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-can-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-can-help",
    "aria-label": "how hearcom can help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Hear.com Can Help?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The importance of hearing your own voice something many people don’t consider until they experience hearing loss. At Hear.com, we understand what you’re going through and are confident that we have effective solutions that will help you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our team of experienced hearing professionals can evaluate your hearing loss and determine which of our hearing aids can work for your level of impairment. We work with the top 2% of hearing professionals nationwide, so you receive a customized product that meets your unique needs."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "get-assistance-from-an-experienced-audiologist-near-your-location",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-assistance-from-an-experienced-audiologist-near-your-location",
    "aria-label": "get assistance from an experienced audiologist near your location permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Assistance from an Experienced Audiologist Near Your Location"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear.com is here to provide you with comfortable and technologically advanced hearing aids. ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire",
    className: "c-md-a"
  }, "Complete our questionnaire"), " if you want to learn more about own voice processing and whether you qualify for a no-risk trial."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
